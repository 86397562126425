import React, { useEffect, useRef, useState, useMemo} from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import axios from "axios";
import Dashboard from '../Dashboard/Dashboard';
import ForestDetails from '../ForestDetails/ForestDetails';
import PotreeViewer from '../PotreeViewer/PotreeViewer';
import PropTypes from 'prop-types'
import './tabs.css'
import {useMediaQuery} from '../../helpers/mediaQuery';
import DropZone from '../DropZone/DropZone';
import useToken from '../App/useToken';
import AdminView from '../AdminTab/AdminView';
import DashboardView from '../DashboardTab/dashboardView';
import { hasAccess } from '../Helper/Helper';


export default function ControlledTabs(props) {
  const {token, getCustomer} = useToken()
  const [customer, setCustomer] = useState(getCustomer())
  const isRowBased = useMediaQuery('(max-width: 420px)');
  const [key, setKey] = useState('map');
  const [selectedBagfile, setSelectedBagfile] = useState({_id: "", name:""})
  const [prevSelectedBagfile, setPrevSelectedBagfile] = useState("")
  const [filterCustomerData, setFilterCustomerData] = useState()
  const filterCustomerDataRef = useRef()
  filterCustomerDataRef.current = filterCustomerData

  const [filterBagData, setFilterBagData] = useState('')
  const filterBagDataRef = useRef()
  filterBagDataRef.current = filterBagData
  const [filterAID, setFilterAID] = useState('')
  const filterAIDRef = useRef()
  filterAIDRef.current = filterAID

  const bagfile = useRef(selectedBagfile)
  const prevBagfileId = useRef(prevSelectedBagfile)
  useEffect(()=>{
    if(!props.loading){
      setFilterCustomerData(customer.customerId)
    }
  }, [props.loading])
  useEffect(() =>{
    // prevBagfileId.current = bagfile.current
    // setPrevSelectedBagfile(bagfile.current)
    bagfile.current = selectedBagfile 
  },[selectedBagfile])

  const setNewSelectedBagFile = (newBagfile) => {
    prevBagfileId.current = selectedBagfile._id ? selectedBagfile._id : ""
    setPrevSelectedBagfile(prevBagfileId.current)
    setSelectedBagfile(newBagfile)
    setRenderPotree(false)
  }
  const [renderPotree, setRenderPotree] = useState(false)
  const [showShapeData, setShowShapeData] = useState(false)
  const showShapeDataRef = useRef(false)
  showShapeDataRef.current = showShapeData
  const [shapeData, setShapeData] = useState(null)
  const shapeDataRef = useRef(null)
  shapeDataRef.current = shapeData
  const [drones, setDrones] = useState([])
  const dronesRef = useRef([])
  dronesRef.current = drones
  const [forestdata, setForestdata] = useState({
    possibleTrees: 0,  
    area: 0, 
    basalArea: 0,
  })
  const forestdataRef = useRef({
    possibleTrees: 0,  
    area: 0, 
    basalArea: 0,
  })
  forestdataRef.current = forestdata
  const [selectedShapeData, setSelectedShapeData] = useState({_id: "", AID: ""})
  const selectedShapeDataRef = useRef({_id: "", AID: ""})
  selectedShapeDataRef.current = selectedShapeData
  // const getShapeData = () => {
  //   const data = {
  //     'token': token,
  //     'customerId': customer
  //   }

  //   // var formData = new FormData();
  //   // var token = sessionStorage.getItem('token')
  //   // formData.append('token', token);
  //   var config = {
  //       method: 'post',
  //       url: "api/get_shapedata",
  //       data: data
  //   };
  //   axios(config)
  //       .then(response => 
  //       {
          
  //         const ResShapedata = response.data.shapedata
  //         if(Array.isArray(ResShapedata)){
  //           setShapeData(ResShapedata)
  //         }
  //       })
  //       .catch(error => {
  //         console.log("error", error)
  //     })
  // }
  const [surveyArea, setSurveyArea] = useState(null)
  const surveyAreaRef = useRef([])
  surveyAreaRef.current = surveyArea
  const getSurveyAreas = async () => {
    const data = {
      'token': token,
      'customerId': filterCustomerDataRef.current
    }
    var config = {
      method: 'post',
      url: "api/get_filtered_surveyarea",
      data: data
  };
  axios(config)
    .then(response => {
        const fetchedData = response.data.surveyAreas
        let pindata = []
        console.log(fetchedData)
        if(Array.isArray(fetchedData)) {
          
          setSurveyArea(fetchedData);
          
        } 
      })            
      .catch(error => {
        console.log('error', error)
      })
  }
  const getCustomerDrones = () => {
    var formData = new FormData()
    formData.append('token', token)
    var config = {
      method: 'post',
      url: "api/get_filtered_drones",
      data: formData
  };
  axios(config)
    .then(response => {
      setDrones(response.data.drones)
      console.log(response.data.drones)

    })
  }
  const ctx = useMemo(
    () => ({
      filterBagData: filterBagDataRef.current,
      filterAID: filterAIDRef.current,
      filterCustomerData : filterCustomerDataRef.current,
      setFilterAID: (filter) => {
        // console.log(filter)
        setFilterAID(filter)},
      setFilterBagData: (filter) => {
        // console.log(filter)
        setFilterBagData(filter)},
      bagData: bagDataRef,
      websckt: webscktRef,
      surveyArea: surveyAreaRef,
      selectedShapeData: selectedShapeDataRef,
      selectShapeData: (item) => {
        setSelectedShapeData(item)
      },
      forestdata: forestdataRef.current,
      setForestdata: ({possibleTrees, area, basalArea}) => {
        setForestdata({
          possibleTrees: possibleTrees,
          area: area, 
          basalArea: basalArea})
      },
      drones: dronesRef.current,
      prevSelectedBagfileId: prevBagfileId.current,
      shapeData: shapeDataRef,
      getShapeData: getSurveyAreas,
      showShapeData: showShapeDataRef,
      toggleShowShapeData: () => {
        if(!surveyAreaRef.current){
          getSurveyAreas()
        }
        setShowShapeData(!showShapeDataRef.current)
      }
    }),
    [drones, shapeData, prevSelectedBagfile, selectedBagfile, websckt,filterCustomerData, bagData, filterBagData, filterAID, surveyArea, showShapeData, forestdata]
  );

  const [bagData, setBagData] = useState(null)
  const bagDataRef = useRef([])
  bagDataRef.current = bagData

  

  const getBagfiles = async () => {
    const data = {
      'token': token,
      'customerId': filterCustomerDataRef.current,
      'filterAID': filterAIDRef.current
    }
    var config = {
        method: 'post',
        url: "api/get_filtered_dronefiles",
        data: data
    };
    axios(config)
      .then(response => {
        const fetchedData = response.data.dronefiles
        let pindata = []
        if(Array.isArray(fetchedData)) {
          
          setBagData(fetchedData);
          
        } 
      })            
      .catch(error => {
        console.log('error', error)
      })
  }

  
  useEffect(() => {
      if(filterCustomerData){
        getCustomerDrones()
        getBagfiles()
      }
      return
  }, [filterCustomerData, filterBagData, filterAID])

  useEffect(() => {
    if(filterCustomerData){
      getSurveyAreas()
    }
    return
  }, [filterCustomerData])

  const [websckt, setWebsckt] = useState(null);
  const url = "wss://portal.deepforestry.com/api/ws/"+token;
  const webscktRef = useRef(null)
  webscktRef.current = websckt
  useEffect(() => {
    const ws = new WebSocket(url)
    webscktRef.current = ws
    setWebsckt(ws);
    ws.onopen = (event) => {
      // console.log(event)
      console.log('Connecting')
      ws.send("Connect");
    };
    
    //clean up function when we close pagew
    return () => { 
      ws.close();
      console.log("Closing socket...")
    }
  },[]);
  useEffect(() => {
    if(!webscktRef.current){ return }
    webscktRef.current.onmessage = (msg) => {
      console.log(msg)
      const data = JSON.parse(msg.data);
      if(data.type === "dronefileAdded" || data.type === "dronefileDeleted"){     
        getBagfiles()
      }
      else if(data.type === "dronefileUpdated"){       
        getBagfiles()
      }
      else if(data.type === "surveyAreaAdded"){
        getSurveyAreas()
      }
      else if(data.type === "surveyAreaUpdated"){
        getSurveyAreas()
      }
      else if(data.type === "droneUpdated"){
        getCustomerDrones()
      }

    }
  },[webscktRef.current])
  const [dropzoneActive, setDropzoneActive] = useState(false)
  const changeDropzoneActive = (active) => {
    setDropzoneActive(active)
  }
  
  const dropZone = ( 
    <DropZone
      show={dropzoneActive}
      onHide={changeDropzoneActive}
      token={token}
    />
    )
  return (
      <Tabs
        id="controlled-tab"
        activeKey={key}
        onSelect={(k) => {
          setKey(k)
          if(k === '3dviewer'){
            setRenderPotree(true)
          }
        }}
        className="my-tabs"
        transition={false}
        style={isRowBased ? {paddingLeft: "0", display: "flex", flexDirection: "column"} : { paddingLeft: "1.5em", flexDirection: "row"}}
      >
        <Tab style={{height: "100%", width:"100%"}} eventKey="map" title="Map view" >
            <Dashboard bagData={bagData} websctRef={webscktRef} ctx={ctx} filterCustomerData={filterCustomerData} setFilterCustomerData={setFilterCustomerData} selectedBagfile={selectedBagfile} setSelectedBagfile={setNewSelectedBagFile} token={props.token} ws={webscktRef.current} changeDropzoneActive={changeDropzoneActive}/>
            {dropZone}
        </Tab>
        <Tab style={{height: "100%"}} eventKey="forestdetails" title="Forest Details">
          <ForestDetails bagData={bagData} ctx={ctx} filterCustomerData={filterCustomerData} setFilterCustomerData={setFilterCustomerData} selectedBagfile={selectedBagfile} setSelectedBagfile={setNewSelectedBagFile} token={props.token} ws={webscktRef.current}/>
        </Tab>
        <Tab style={{height: "100%"}} eventKey="3dviewer" title="3D Viewer" >
          <PotreeViewer selectedBagfile={selectedBagfile} setSelectedBagfile={setNewSelectedBagFile} renderPotree={renderPotree}/>
        </Tab>
        {customer && hasAccess(customer) ? (<Tab style={{height: "100%"}} eventKey="dashboard" title="Dashboard" >
          <DashboardView ws={websckt} bagData={bagData} selectedBagfile={selectedBagfile} setSelectedBagfile={setNewSelectedBagFile}/>
        </Tab>) : null}
        {customer && hasAccess(customer) ? 
        (<Tab style={{height: "100%", width:"100%"}} eventKey="missionview" title="Admin" >
          <AdminView bagData={bagData}/>
        </Tab>) : null}
      </Tabs>
  );
}

ControlledTabs.propTypes = {
  token: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  }